import React from "react"

import PageFooter from "./PageFooter"

const footerData = {
  items: [
    {
      title: "SRF Apps",
      href: "https://www.srf.ch/hilfe/website-und-apps/srf-apps"
    },
    {
      title: "Korrekturen",
      href: "https://www.srf.ch/srf-korrekturen"
    },
    {
      title: "Datenschutz",
      href: "https://www.srf.ch/rechtliches"
    },
    {
      title: "Jobs",
      href: "https://jobs.srf.ch/de/startseite/"
    },
    {
      title: "Hilfe",
      href: "https://www.srf.ch/hilfe"
    },
    {
      title: "Kontakt",
      href: "https://www.srf.ch/hilfe-kontakt"
    },
    {
      title: "Medien",
      href: "https://medien.srf.ch"
    },
    {
      title: "Impressum",
      href: "https://www.srf.ch/impressum"
    }
  ],
  imprint: {
    link: "https://www.srgssr.ch",
    image: {
      src: "https://www.srf.ch/var/storage/assets/webpack/images/srgssr_logo.22ced7ec.svg",
      alt: "Logo der SRG SSR"
    },
    description: "SRF Schweizer Radio und Fernsehen,<br>Zweigniederlassung der Schweizerischen Radio- und Fernsehgesellschaft"
  }
}

const SrfPageFooter = ({debug}) => {
  return <PageFooter {...footerData} {...{debug}}/>
}

export default SrfPageFooter